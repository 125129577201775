import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    padding: '3px 6px',
    gap: '3px',
  },
  item: {
    padding: '9px 18px',
    margin: '3px 0',
    borderRadius: '6px',
    transition: 'background-color 240ms ease-in-out',
  },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });

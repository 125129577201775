export const GLOBAL_ADMIN_PERMISSIONS = {
  globalAdmin: 'canGlobalAdmin',
} as const;
/**
 * STANDARD_PERMISSIONS defines granular access controls sourced from the database,
 * categorized into user types and granular permissions.
 *
 * Permissions are granular and directly checked, whereas user types like 'portalAdmin' are not
 * directly checked. Instead, specific user types can confer various permissions. For instance,
 * a 'portalAdmin' has all permissions implicitly.
 *
 * Refer to `applyPermissions` to see how STANDARD_PERMISSIONS are used to determine
 * the permissions (abilities) to apply to each client.
 */
export const STANDARD_PERMISSIONS = {
  // User types
  member: 'member',
  staff: 'staff',
  public: 'public',

  portalAdmin: 'canPortalAdmin', // Grants all below permissions implicitly

  // Granular permissions
  manageProjects: 'canManageProjects',
  manageUsers: 'canManageUsers',
  manageForms: 'canManageForms',
  manageResources: 'canManageResources',
  managePages: 'canManagePages',

  // User types
} as const;

export const ALL_PERMISSIONS = {
  ...GLOBAL_ADMIN_PERMISSIONS,
  ...STANDARD_PERMISSIONS,
};

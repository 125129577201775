import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  history: definePartsStyle({
    container: {
      padding: 0,
      borderColor: 'gray.300',
      backgroundColor: 'white',
      borderRadius: 'xl',
      boxShadow: 'md',
      overflow: 'auto',
    },
    header: {
      pt: 4,
      px: 4,
      pb: 4,
    },
    body: {
      px: 4,
      pb: 4,
      pt: 0,
    },
    footer: {
      py: 2,
      px: 4,

      backgroundColor: 'gray.100',
      borderTopColor: 'gray.300',
      justifyContent: 'center',
    },
  }),
  standard: definePartsStyle({
    container: {
      padding: 4,
      borderColor: 'gray.300',
      backgroundColor: 'white',
      borderRadius: 'xl',
      boxShadow: 'md',
      overflow: 'auto',
    },
    header: {
      p: 0,
    },
    body: {
      mt: 4,
      p: 0,
    },
    footer: {
      mt: 4,
      p: 0,
    },
  }),
};

// export variants in the component theme
export const cardTheme = defineMultiStyleConfig({ variants });

export const SUBJECTS = {
  globalAdmin: 'globalAdmin',

  projects: 'projects',
  requests: 'requests',
  users: 'users',
  forms: 'forms',
  resources: 'resources',
  pages: 'pages',
  meetings: 'meetings',

  boardTools: 'boardTools',
} as const;

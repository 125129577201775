import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const defaultVariant = definePartsStyle({
  body: {
    height: 'fit-content',
    margin: '12px 0 20px 0',
    // @todo: consider removing this padding
    padding: '0 12px',
  },
  dialog: {
    // make it smaller for mobile
    '@media (max-width: 480px)': {
      padding: '24px 8px',
    },

    padding: '24px 40px',
    // maxHeight: 'calc(100vh - 128px)',
  },
  footer: {
    padding: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 24px 0 24px',
  },
  overlay: {},
});

const updateVariant = definePartsStyle({
  body: {
    padding: '20px 0 0',
  },
  dialog: {
    padding: '24px 48px',
  },
  dialogContainer: {
    padding: '24px 0',
  },
  header: {
    border: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0',
  },
  overlay: {},
});

const compactVariant = definePartsStyle({
  body: {
    padding: 0,
    textAlign: 'center',
  },
  dialog: {
    padding: '24px 20px',
  },
  header: {
    padding: '12px',
    textAlign: 'center',
  },
});

export const modalTheme = defineMultiStyleConfig({
  defaultProps: {
    size: 'xl',
  },
  sizes: {
    xl: {
      dialog: {
        maxWidth: '600px',
      },
    },
  },
  variants: {
    default: defaultVariant,
    compact: compactVariant,
    update: updateVariant,
  },
});

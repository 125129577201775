'use client';

import '@/yup.ts';

import { CacheProvider } from '@chakra-ui/next-js';
import { ModalsProvider, SaasProvider } from '@saas-ui/react';
import { SessionProvider } from 'next-auth/react';
import { ReactNode } from 'react';

import { theme } from '../theme';

import { AbilityProvider } from '@/contexts/AbilityProvider';
import { VisibilityFocusProvider } from '@/contexts/VisibilityFocusProvider';
import { ApolloProviderWrapper } from '@/data/ApolloProvider';
import { HighlightIdentifyProvider } from '@/hooks/useHighlightIdentify';
// Has to be imported here to initialize socket connection in this file
// import '@/hooks/useSocketEvents';

interface ProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  return (
    <SessionProvider>
      <AbilityProvider>
        <HighlightIdentifyProvider>
          <ApolloProviderWrapper>
            <CacheProvider>
              <SaasProvider theme={theme}>
                <ModalsProvider>
                  <VisibilityFocusProvider>{children}</VisibilityFocusProvider>
                </ModalsProvider>
              </SaasProvider>
            </CacheProvider>
          </ApolloProviderWrapper>
        </HighlightIdentifyProvider>
      </AbilityProvider>
    </SessionProvider>
  );
}

import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
    boxShadow: 'xs',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  defaultProps: {
    size: 'sm',
    variant: 'outline',
  },
  sizes: {
    lg: {
      fontSize: 'md',
      height: '44px',
      fontWeight: 600,
      lineHeight: 'md',
      minWidth: 'fit-content',
      px: '36px',
      py: '10px',
    },
    md: {
      fontSize: 'sm',
      height: '40px',
      lineHeight: 'sm',
      minWidth: 'fit-content',
    },
    sm: {
      fontSize: 'sm',
      height: '36px',
      lineHeight: 'sm',
      minWidth: 'fit-content',
      px: '12px',
      py: '7px',
    },
  },
  variants: {
    danger: {
      '&:hover, &:hover[disabled]': {
        backgroundColor: 'error.700',
        borderColor: 'inherit',
      },
      backgroundColor: 'error.600',

      borderColor: 'error.600',
      borderWidth: '1px',

      color: '#ffffff',
    },
    ghost: {
      '&:hover': {
        backgroundColor: 'gray.50',
      },

      backgroundColor: 'transparent',
      boxShadow: 'none',

      color: 'inherit',
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'gray.50',
      },
      aspectRatio: '1',
      border: 'none',
      borderRadius: '50%',

      // https://github.com/chakra-ui/chakra-ui/issues/3746
      boxShadow: 'none',
      height: '24px',
      width: '24px',
    },
    link: {
      '&:hover': {
        color: 'primary.700',
      },
      boxShadow: 'none',
      color: 'gray.600',
      fontSize: 'sm',
      fontWeight: 400,
      padding: '2px',

      textDecoration: 'underline',
    },
    navItem: {
      '&:hover': {
        backgroundColor: 'gray.50',
      },

      background: 'primary.50',
      boxShadow: 'none',

      color: 'primary.700',
    },
    outline: {},
    outlineDanger: {
      borderWidth: '1px',
      color: 'error.700',
      borderColor: 'error.300',
    },
    primary: {
      '&:hover': {
        backgroundColor: 'primary.700',
      },
      backgroundColor: 'primary.600',

      color: 'white',
    },
    secondary: {
      '&:hover': {
        backgroundColor: 'gray.50',
        borderColor: 'inherit',
      },
      backgroundColor: '#ffffff',
      borderColor: 'gray.300',

      borderWidth: '1px',
      color: 'inherit',

      minWidth: 'fit-content',
    },
  },
});

'use client';

import { useEffect, useState } from 'react';

export function usePageFocus() {
  const [isPageFocused, setIsPageFocused] = useState(true);

  // Handle focus/blur change change
  useEffect(() => {
    const handleFocusChange = () => {
      setIsPageFocused(true);
    };

    const handleBlurChange = () => {
      setIsPageFocused(false);
    };

    window.addEventListener('focus', handleFocusChange);
    window.addEventListener('blur', handleBlurChange);

    return () => {
      window.removeEventListener('focus', handleBlurChange);
      window.removeEventListener('blur', handleFocusChange);
    };
  }, []);

  return isPageFocused;
}

'use client';

import { createContext, ReactNode, useContext } from 'react';

import { usePageFocusDelay } from '@/hooks/usePageFocusDelayed';
import { usePageVisibility } from '@/hooks/usePageVisibility';

interface VisibilityFocusProviderProps {
  children: ReactNode;
}

const VisibilityFocusContext = createContext<{
  isPageFocusedWithDelay?: boolean;
  isPageVisible?: boolean;
}>({});

export const useVisibilityFocus = () => useContext(VisibilityFocusContext);

export function VisibilityFocusProvider({
  children,
}: VisibilityFocusProviderProps) {
  const isPageVisible = usePageVisibility();
  const isPageFocusedWithDelay = usePageFocusDelay();

  return (
    <VisibilityFocusContext.Provider
      value={{
        isPageFocusedWithDelay,
        isPageVisible,
      }}
    >
      {children}
    </VisibilityFocusContext.Provider>
  );
}

'use client';

import { HighlightInit } from '@highlight-run/next/client';

export function HighlightRunner({
  serviceName,
  version,
}: {
  serviceName: string;
  version: string;
}) {
  console.log('HighlightInit - ' + serviceName + ' version: ' + version);

  return (
    <HighlightInit
      projectId="memjv6pe"
      serviceName={serviceName}
      version={version}
      // canvas recording
      enableCanvasRecording={true} // enable canvas recording
      samplingStrategy={{
        canvas: 2, // snapshot at 2 fps
        canvasMaxSnapshotDimension: 480, // snapshot at a max 480p resolution
      }}
      //
      networkRecording={{
        enabled: true,
        recordHeadersAndBody: true,
        networkHeadersToRedact: [
          'Authorization',
          'Cookie',
          'Proxy-Authorization',
        ],
        networkBodyKeysToRedact: ['jwt'],
        requestResponseSanitizer: (pair) => {
          // Check if this is a GraphQL request
          if (
            pair.request.url.includes('/graphql') ||
            pair.request.body.includes('query')
          ) {
            // Parse the request body to get the operation name
            const body = JSON.parse(pair.request.body);
            const operationName = body.operationName;

            // List of GraphQL operations to exclude
            const excludedOperations = [
              'CountNotifications',
              'GetProjectLastChangedAt',
            ];

            if (excludedOperations.includes(operationName)) {
              // Exclude this operation by returning null
              return null;
            }
          }

          // check if its a auth/session request
          if (pair.request.url.includes('api/auth/session')) {
            // Exclude this operation by returning null
            return null;
          }

          // For non-excluded requeste pair as-is
          return pair;
        },
      }}
      environment={process.env.NEXT_PUBLIC_ENV}
      reportConsoleErrors={true}
      privacySetting="none"
      enableCanvasRecording={false}
      consoleMethodsToRecord={[
        'assert',
        'count',
        'countReset',
        'debug',
        'dir',
        'dirxml',
        // 'error',
        'group',
        'groupCollapsed',
        'groupEnd',
        'info',
        'log',
        'table',
        'time',
        'timeEnd',
        'timeLog',
        'trace',
        'warn',
      ]}
      tracingOrigins
    />
  );
}

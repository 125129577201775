import { defineStyleConfig } from '@chakra-ui/react';

export const Text = defineStyleConfig({
  baseStyle: {
    color: 'gray.900',
  },
  sizes: {
    lg: {
      fontSize: '18px',
      lineHeight: '28px',
    },
    md: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    sm: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    xl: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    xs: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
});

import { addMethod, setLocale, string } from 'yup';

import { phoneNumberPattern, slugifiedPattern } from './utils/patterns';

declare module 'yup' {
  // eslint-disable-next-line no-unused-vars
  interface StringSchema {
    numerable(): this;
    phoneNumber(): this;
    phoneNumberAmerican(): this;
    slugified(message: string): this;
  }
}

addMethod(string, 'numerable', function numerable() {
  return this.matches(/^(-)?[0-9]*(\.[0-9]+)?$/, 'Please provide a number');
});

addMethod(string, 'slugified', function slugified(message: string) {
  return this.matches(slugifiedPattern, message);
});

addMethod(string, 'phoneNumber', function phoneNumber(e, ...a) {
  return this.matches(phoneNumberPattern, {
    excludeEmptyString: true,
    message: 'Please provide a valid phone number (e.g. (555) 555-5555)',
  });
});

addMethod(string, 'notOneOf', function notOneOf(list, message) {
  return this.test('notOneOf', message, (value: string) => {
    return !list.includes(value);
  });
});

setLocale({
  array: {
    min: 'Please select at least ${min} items',
  },
  mixed: {
    required: 'This field is required',
  },
  string: {
    max: 'Please enter at most ${max} characters',
    min: 'Please enter at least ${min} characters',
    url: 'Please provide a valid URL',
  },
  number: {
    integer: 'Please provide an integer',
  },
});

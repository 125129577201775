'use client';

import { ReactNode, useEffect } from 'react';

export function RedirectGuard({
  children,
  redirectToWWW,
}: {
  children: ReactNode;
  redirectToWWW: boolean;
}) {
  useEffect(() => {
    if (redirectToWWW) {
      window.location.href = 'https://www.municollab.com';
    }
  }, [redirectToWWW]);

  if (redirectToWWW) {
    return (
      <div>
        <p>Redirecting to www.municollab.com</p>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

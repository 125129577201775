'use client';

import { createContext, ReactNode, useContext } from 'react';

import { Municipality } from '@/types/municipality';

interface MunicipalityProviderProps {
  availableMunicipalities: Municipality[];
  children: ReactNode;
  municipality: Municipality;
}

const MunicipalityContext = createContext<{
  availableMunicipalities: Municipality[];
  municipality: Partial<Municipality>;
}>({
  availableMunicipalities: [],
  municipality: {
    entities: [],
  },
});

export const useMunicipality = () => useContext(MunicipalityContext);

export function MunicipalityProvider({
  availableMunicipalities,
  children,
  municipality,
}: MunicipalityProviderProps) {
  return (
    <MunicipalityContext.Provider
      value={{ municipality, availableMunicipalities }}
    >
      {children}
    </MunicipalityContext.Provider>
  );
}

import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const customVariant = definePartsStyle(() => {
  return {
    tab: {
      _selected: {
        bg: 'gray.200',
        color: 'gray.700',
        mb: '-2px',
      },
      borderBottom: 'none',
      borderTopRadius: '6px',
      color: 'gray.500',
      fontWeight: '600',
      mb: '-2px',
    },
  };
});

// @todo: refine this name to something more meaningful
// For now it's the only variant we have, so it's fine
const variants = {
  custom: customVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants });

import { ACTIONS_ALL } from './actions';
import { ALL_PERMISSIONS } from './permissions';
import { SUBJECTS } from './subjects';

import { ValuesOf } from '@/types/helpers';

type PermissionApplyFunction = (args: {
  ability: any;
  entityId: string;
}) => void;

/**
 * This file configures how permissions are applied in the application using CASL/Ability. Permissions
 * are defined based on user roles (e.g., globalAdmin, portalAdmin) and specific permissions
 * (e.g. canManageProjects, canManageUsers).
 *
 * The applyPermissions object maps permissions and user roles to functions that define what actions can be performed (read, write, all)
 * on which subjects (e.g., projects, users)
 * Each function specifies granular access control that can be applied to different
 * entities (identified by entityId) under different contexts.
 *
 * @example
 * having a user with only one permission - `canManageProjects` for entityId '123'
 * and calling `ability.can(ACTION_WRITE, SUBJECTS.projects, entityId)` somewhere in the project (with useAbility from '@/hooks/useAbility')
 * will return true on entity '123', but will return false on entityId '124'
 */
export const applyPermissions: Record<
  ValuesOf<typeof ALL_PERMISSIONS>,
  PermissionApplyFunction
> = {
  // GLOBAL ADMIN PERMISSIONS
  [ALL_PERMISSIONS.globalAdmin]: ({ ability }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.globalAdmin);
  },
  // ADMIN PERMISSIONS - implicitly grants all permissions
  [ALL_PERMISSIONS.portalAdmin]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.projects, [entityId]);
    ability.can(ACTIONS_ALL, SUBJECTS.users, [entityId]);
    ability.can(ACTIONS_ALL, SUBJECTS.forms, [entityId]);
    ability.can(ACTIONS_ALL, SUBJECTS.resources, [entityId]);
    ability.can(ACTIONS_ALL, SUBJECTS.pages, [entityId]);
    ability.can(ACTIONS_ALL, SUBJECTS.boardTools, [entityId]);
    ability.can(ACTIONS_ALL, SUBJECTS.meetings, [entityId]);
  },
  // PERMISSIONS
  [ALL_PERMISSIONS.manageProjects]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.projects, [entityId]);
  },
  [ALL_PERMISSIONS.manageUsers]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.users, [entityId]);
  },
  [ALL_PERMISSIONS.manageForms]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.forms, [entityId]);
  },
  [ALL_PERMISSIONS.manageResources]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.resources, [entityId]);
  },
  [ALL_PERMISSIONS.managePages]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.pages, [entityId]);
  },
  // USER TYPES
  [ALL_PERMISSIONS.staff]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.boardTools, [entityId]);
  },
  [ALL_PERMISSIONS.member]: ({ ability, entityId }) => {
    ability.can(ACTIONS_ALL, SUBJECTS.boardTools, [entityId]);
  },
  [ALL_PERMISSIONS.public]: () => {},
};

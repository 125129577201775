'use client';

import { createContextualCan } from '@casl/react';
import { useSession } from 'next-auth/react';
import { createContext, ReactNode } from 'react';

import { defineAbilityFor } from '../permissions/defineAbilityFor';

import { getAbility } from '@/permissions/getAbility';

const defaultAbility = defineAbilityFor();
export const AbilityContext = createContext(defaultAbility);

export function AbilityProvider({ children }: { children: ReactNode }) {
  const { data } = useSession();

  const ability = getAbility(data?.entitiesPermissions);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}

export const Can = createContextualCan(AbilityContext.Consumer);

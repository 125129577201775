import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const inputTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    group: {
      background: 'white',
      borderRadius: '8px',
    },
    field: {
      color: 'gray.900',
      _placeholder: {
        color: 'gray.500',
      },
      background: 'white',
      borderRadius: '8px',
      boxShadow: 'xs',
      height: '44px',
      paddingBottom: '6.5px',
      paddingTop: '6.5px',
    },
  }),
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
  sizes: {
    md: definePartsStyle({
      field: {
        height: 'fit-content',
        minHeight: '44px',
      },
    }),
    sm: definePartsStyle({
      field: {
        height: 'fit-content',
        minHeight: '40px',
      },
    }),
    xs: definePartsStyle({
      field: {
        fontSize: 'sm',
        height: 'fit-content',
        minHeight: '32px',
        paddingBottom: '0',
        paddingTop: '0',
      },
    }),
    // @todo: unify the styles between all input types
    selectXs: definePartsStyle({
      field: {
        height: 'fit-content',
        minHeight: '36px',
        paddingBottom: '1px',
        paddingTop: '1px',
        fontSize: 'sm',
        lineHeight: '24px',
        paddingLeft: '14px !important',
      },
    }),
  },
  variants: {
    outline: definePartsStyle({
      field: {
        _hover: {
          borderColor: 'gray.400',
          boxShadow: 'sm',
        },
        borderColor: 'gray.300',
      },
    }),
  },
});

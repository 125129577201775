import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';

import { ACTIONS_ALL } from './actions';
import { applyPermissions } from './applyPermissions';
import { ALL_PERMISSIONS } from './permissions';
import { SUBJECTS } from './subjects';

import { ValuesOf } from '@/types/helpers';

type AppAbilityType = Ability<
  [(typeof ACTIONS_ALL)[number], ValuesOf<typeof SUBJECTS>]
>;

const AppAbility = Ability as AbilityClass<AppAbilityType>;

type AbilityOption = {
  entityId: string;
  value: ValuesOf<typeof ALL_PERMISSIONS>;
};

const definePermissionsFor = (permissionGroups: AbilityOption[] = []) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbilityType>(AppAbility);

  for (const { entityId, value } of permissionGroups) {
    if (!(value in applyPermissions)) {
      continue;
    }

    applyPermissions[value]({
      ability: { can, cannot },
      entityId: entityId,
    });
  }

  return rules;
};

export function defineAbilityFor(permissionGroups: AbilityOption[] = []) {
  return new AppAbility(definePermissionsFor(permissionGroups));
}

'use client';

import { useEffect, useState } from 'react';

export function usePageVisibility() {
  const [isPageVisible, setIsPageVisible] = useState(false);

  // Initial loading - we need to wait for the document to be ready (SSR)
  useEffect(() => {
    setIsPageVisible(!document.hidden);
  }, []);

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isPageVisible;
}

'use client';

import { useEffect, useState } from 'react';

import { usePageFocus } from './usePageFocus';

const DELAY = 30 * 1000; // 30 seconds

export function usePageFocusDelay() {
  const isFocused = usePageFocus();
  const [isFocusedWithDelay, setIsFocusedWithDelay] = useState(isFocused);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isFocused) {
      // When the page loses focus, start a timer
      const id = setTimeout(() => {
        setIsFocusedWithDelay(false);
      }, DELAY);

      setTimeoutId(id);
    } else {
      // When the page regains focus, clear the timer and reset the state
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
        setIsFocusedWithDelay(true);
      }
    }

    // Cleanup when the component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return isFocusedWithDelay;
}

import { defineAbilityFor } from './defineAbilityFor';
import { ALL_PERMISSIONS } from './permissions';

import { ValuesOf } from '@/types/helpers';

export function getAbility(entitiesPermissions: any[] = []) {
  // Link entityId with userType
  const entityIdsUserTypes = entitiesPermissions.map(({ entityId, type }) => ({
    entityId,
    value: type satisfies ValuesOf<typeof ALL_PERMISSIONS>,
  }));

  // Link entityId with each permission
  const entityIdsPermissions = entitiesPermissions
    .flatMap(
      ({ config, entityId }) =>
        config.permissions?.map((permission: string) => ({
          entityId,
          value: permission,
        })),
    )
    .filter(Boolean) as { entityId: string; value: UserPermission }[]; // TS is stupid

  const ability = defineAbilityFor([
    ...entityIdsUserTypes,
    ...entityIdsPermissions,
  ]);

  return ability;
}

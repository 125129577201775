export const colors = {
  blueLight: {
    100: '#E0F2FE',
    200: '#B9E6FE',
    900: '#0B4A6F',
  },
  brand: {
    700: '#2A69AC',
    800: '#153E75',
    900: '#1A365D',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    300: '#FDA29B',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
  },
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  green: {
    100: '#E4FBCC',
    500: '#12B76A',
    600: '#099250',
  },
  primary: {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
  },
  yellow: {
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FDE6B0',
    300: '#FCD98A',
    500: '#F5A623',
    600: '#CA8504',
  },
};

'use client';

import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getSession } from 'next-auth/react';
import { ReactNode, useMemo } from 'react';

import { inMemoryCacheConfig } from '@/helpers/apollo-helpers';

const uri = process.env.NEXT_PUBLIC_GRAPHQL_URL
  ? process.env.NEXT_PUBLIC_GRAPHQL_URL
  : 'http://localhost:4000/graphql';
const httpLink = new HttpLink({
  credentials: 'include',
  uri: uri,
});

export const ApolloProviderWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const client = useMemo(() => {
    const authMiddleware = setContext(async (operation, { headers }) => {
      const session = await getSession();

      if (session?.jwt) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${session.jwt}`,
          },
        };
      } else {
        // if not authenticated
        return {
          headers: {
            ...headers,
          },
        };
      }
    });

    return new ApolloClient({
      cache: new InMemoryCache(inMemoryCacheConfig),
      link: from([authMiddleware, httpLink]),
      connectToDevTools: process.env.NEXT_PUBLIC_ENV === 'local',
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

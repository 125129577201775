import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  borderRadius: '8px',
  boxShadow: 'xs',
});

const outline = defineStyle({
  _hover: {
    borderColor: 'gray.400',
    boxShadow: 'sm',
  },
  borderColor: 'gray.300',
  borderRadius: '8px',
  padding: '8px 12px',
});

export const textareaTheme = defineStyleConfig({
  baseStyle,
  variants: { outline },
});

import { defineStyleConfig } from '@chakra-ui/react';

// @todo: investigate these styles
export const Heading = defineStyleConfig({
  baseStyle: {
    color: 'gray.900',
  },
  sizes: {
    xs: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    sm: {
      fontSize: '30px',
      lineHeight: '38px',
    },
    md: {
      fontSize: '36px',
      lineHeight: '44px',
      letterSpacing: '-0.72px',
    },
    xl: {
      fontSize: '60px',
      lineHeight: '72px',
      lineSpacing: '1.2px',
    },
  },
});

import { defineStyleConfig } from '@chakra-ui/react';

export const Table = defineStyleConfig({
  baseStyle: {
    thead: {
      backgroundColor: 'gray.25',

      th: {
        color: 'gray.600',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
        textTransform: 'capitalize',
      },

      tr: {
        height: '44px',
      },
    },
    tr: {
      fontSize: '14px',
      fontWeight: 400,
      height: '72px',
      lineHeight: '20px',
    },
  },
});

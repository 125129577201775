import { H } from '@highlight-run/next/client';
import { useSession } from 'next-auth/react';
import { ReactNode } from 'react';

export function useHighlightIdentify() {
  const session = useSession();
  const email = session.data?.user?.email;
  const shouldIdentifyHighlight =
    typeof email === 'string' &&
    (process.env.NEXT_PUBLIC_ENV === 'production' ||
      process.env.NEXT_PUBLIC_ENV === 'development');

  if (shouldIdentifyHighlight) {
    const { firstName, lastName } = (session.data?.user ?? {}) as any;

    const highlightDisplayName =
      firstName && lastName ? `${firstName} ${lastName}` : email;

    H.identify(email, {
      highlightDisplayName,
    });
  }
}

export function HighlightIdentifyProvider({
  children,
}: {
  children: ReactNode;
}) {
  useHighlightIdentify();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
